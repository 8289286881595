@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300&display=swap);
/* generalities */
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Noto Sans HK', sans-serif;
  text-align: center;
  position: relative;
  margin: 0;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.main-container {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
a {
  color: inherit;
}
p {
  font-size: large;
  line-height: 1.3;
}

h1 {
  font-weight: bold !important;
}

/* general image styles */
img {
  width: 100%;
  height: auto;
}
.full-width-img {
  width: 100%;
  height: 300px;
}
.svg {
  height: 300px;
  object-fit: cover;
}

/* home page */
.logo {
  margin: auto;
  max-width: 80%;
  width: 10em;
}

.input-group-text {
  /* min-width: 100px; */
  white-space: nowrap;
}


.navbar-light .navbar-toggler {
  border: none;
}

/* services */
.service-card-img {
  height: 100px;
  object-fit: cover;
}
.service-card-img-taller {
  height: 250px;
  object-fit: cover;
}

/* about */
.about-img {
  width: auto;
  max-height: 400px;
  max-width: 300px;
}

/* color schemes */
.blue-gradient {
  background: rgb(91, 153, 254);
  background: linear-gradient(
    257deg,
    rgba(91, 153, 254, 1) 16%,
    rgba(39, 146, 196, 1) 45%,
    rgba(0, 212, 255, 1) 100%
  );
}

.green-gradient {
  background: rgb(91, 153, 254);
  background: linear-gradient(
    108deg,
    rgba(91, 153, 254, 1) 0%,
    rgba(39, 196, 111, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

ul li {
  list-style-type: none;
}

/* .accordion-button::after {
   margin: auto;
} */

.accordion-button {
  font-size: 1.3rem;
  margin: 1.5rem 0;
  background: linear-gradient(
    108deg,
    rgba(91, 153, 254, 0.9) 0%,
    rgba(39, 196, 111, 0.9) 0%,
    rgba(0, 212, 255, 0.9) 100%
  );
  border: none;
  color: white;
}

.accordion-button:not(.collapsed) {
  background: none;
  color: black;
  box-shadow: none;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  color: black;
}

/* button */
.button {
  margin: 0 10px 10px 0;
  background-color: #4e9af1;
}
/* button */
a.button {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
  margin: 15px 0 30px 0;
  position: relative;
    z-index: 2;
}
a.button:hover {
  border-color: rgba(255, 255, 255, 1);
}

.equal-height {
  height:100%;
  object-fit: cover;
}

.rotatated-bg-green {
  background: linear-gradient(
    108deg,
    rgba(91, 153, 254, 0.5) 0%,
    rgba(39, 196, 111, 0.5) 0%,
    rgba(0, 212, 255, 0.5) 100%
  );
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}


.rotatated-bg-blue {
  background: linear-gradient(
    257deg,
    rgba(91, 153, 254, 0.5) 16%,
    rgba(39, 146, 196, 0.5) 45%,
    rgba(0, 212, 255, 0.5) 100%
  );
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}

.rotatated-bg-green img, .rotatated-bg-blue img {
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
}

/* .accordion-button::after {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f106"; font-style: normal;
}
.accordion-button.collapsed::after {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f107"; font-style: normal;
  transition: .3s transform ease-in-out;
} */



/* mobile */
@media only screen and (max-width: 768px) {
  .img-large-mobile {
    overflow: hidden;
  }
  .img-large-mobile img  {
    -webkit-transform: scale(2);
            transform: scale(2);   
    object-fit: cover;
    -webkit-transform-origin: 30% 50%;
            transform-origin: 30% 50%; 
  }
  .img-large-mobile h5, .img-large-mobile h1 {
    position: relative;
    z-index: 1;
  }
 
}

